import {faker} from '@faker-js/faker'

import {
  Item,
  List,
  School,
  SchoolLists,
  SearchLevel,
  SentList,
  User,
} from '../types'
import {capitalize} from '../utils'
import * as types from 'teachers-types'
import {series} from './series'

export function getFakeId() {
  return faker.database.mongodbObjectId()
}

/**
 * Returns a fake item
 */
export function itemFactory(): Item {
  const academicLevel = faker.helpers.arrayElement(
    Object.keys(types.levels) as types.AcademicLevel[],
  )
  return {
    id: faker.database.mongodbObjectId(),
    type: 'Text',
    thumbnail: faker.image.abstract(210, 297, true),
    title: capitalize(
      faker.lorem.words(faker.datatype.number({min: 2, max: 4})),
    ),
    description: faker.commerce.productDescription(),
    author: faker.name.fullName(),
    editorial: faker.company.name(),
    academicLevel,
    approach: faker.helpers.arrayElement(types.approaches),
    cefrLevel: faker.helpers.arrayElement(types.cefrLevels),
    certificate: faker.helpers.arrayElement(types.certificates),
    component: faker.helpers.arrayElement(types.components),

    curriculum: faker.helpers.arrayElement(types.curriculums),
    genre: faker.helpers.arrayElement(types.genres),
    cycle: faker.helpers.arrayElement(types.cycles),
    format: faker.helpers.arrayElement(types.formats),
    edition: faker.datatype.number({min: 2001, max: 2022}),
    grade: faker.helpers.arrayElement([...types.levels[academicLevel]]),
    isbn: faker.datatype.uuid(),
    kelCode: faker.datatype.number({min: 10000, max: 99999}),
    language: faker.helpers.arrayElement(types.languages),
    serie: faker.helpers.arrayElement(series),
    subject: faker.helpers.arrayElement(types.subjects),
    workload: faker.helpers.arrayElement(types.workloads),
    price: faker.helpers.arrayElement([
      100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
    ]),
    isFeatured: faker.datatype.boolean(),
    isRecommended: faker.datatype.boolean(),
    showroom: [
      ...faker.helpers.uniqueArray(
        showroomPdfFactory,
        faker.datatype.number({min: 1, max: 2}),
      ),
      ...faker.helpers.uniqueArray(
        showroomWebsiteFactory,
        faker.datatype.number({min: 1, max: 2}),
      ),
    ],
  }
}

function showroomPdfFactory(): types.ShowroomPdf {
  return {
    type: 'pdf',
    title: capitalize(
      faker.lorem.words(faker.datatype.number({min: 1, max: 3})),
    ),
    thumbnail: faker.image.abstract(210, 297, true),
    url: faker.internet.url() + '/' + faker.system.commonFileName('pdf'),
  }
}

function showroomWebsiteFactory(): types.ShowroomWebsite {
  return {
    type: 'website',
    title: capitalize(
      faker.lorem.words(faker.datatype.number({min: 3, max: 5})),
    ),
    url: faker.internet.url(),
  }
}

/**
 * Returns a fake user list with a ramdom amount of items, based on the provided
 * items collection
 */
export function listFactory(collection: Item[], status: List['status']): List {
  return {
    id: faker.database.mongodbObjectId(),
    name: capitalize(
      faker.lorem.words(faker.datatype.number({min: 2, max: 4})),
    ),
    items: faker.helpers.arrayElements(
      collection,
      faker.datatype.number({min: status === 'draft' ? 0 : 1, max: 15}),
    ),
    status,
  }
}

/**
 * Returns a fake sent list
 */
export function sentListFactory(
  collection: Item[],
  status: List['status'],
): SentList {
  return {
    ...listFactory(collection, status),
    author: userFactory('teacher'),
    studentsAmount: faker.datatype.number({min: 10, max: 50}),
    message: faker.helpers.maybe(() => faker.lorem.sentence()),
  }
}

/**
 * Returns a random lists status
 */
function getRandomListStatus(): List['status'] {
  return faker.helpers.arrayElement(['pending approval', 'approved', 'draft'])
}

/**
 * Returns a random array of fake user lists with at least a few "draft" lists
 */
export function userListsFactory(collection: Item[]): List[] {
  return [
    // ensure we initially have at least a few draft lists
    ...faker.helpers.uniqueArray(
      () => listFactory(collection, 'draft'),
      faker.datatype.number({min: 3, max: 5}),
    ),
    // ensure we initially have at least one approved list
    listFactory(collection, 'approved'),
    // ensure we initially have at least one pending approval list
    listFactory(collection, 'pending approval'),
    ...faker.helpers.uniqueArray(
      () => listFactory(collection, getRandomListStatus()),
      faker.datatype.number({min: 3, max: 5}),
    ),
  ]
}

/**
 * Returns a fake school with a map of sent user lists organized by level
 */
function schoolListsFactory(collection: Item[]): SchoolLists {
  // Generate a randomized range of levels
  const levelsArray = Object.entries(types.levels)
  const lowestLevel = faker.datatype.number({
    min: 0,
    max: levelsArray.length - 1,
  })
  const highestLevel =
    lowestLevel + faker.datatype.number({min: 1, max: levelsArray.length})
  const levelsRange = levelsArray.slice(lowestLevel, highestLevel)

  // Fill the levels range with random lists from random users
  const schoolLevels: SchoolLists['levels'] = {}
  levelsRange.forEach(([level, grades]) => {
    const createLists = () => [
      ...faker.helpers.uniqueArray(
        () => sentListFactory(collection, 'pending approval'),
        faker.datatype.number({min: 0, max: 2}),
      ),
      ...faker.helpers.uniqueArray(
        () => sentListFactory(collection, 'approved'),
        faker.datatype.number({min: 0, max: 1}),
      ),
    ]
    if (grades.length > 0) {
      grades.forEach(grade => {
        schoolLevels[`${level}|${grade}` as keyof SchoolLists['levels']] =
          createLists()
      })
    } else {
      schoolLevels[level as keyof SchoolLists['levels']] = createLists()
    }
  })

  return {
    id: faker.database.mongodbObjectId(),
    name: capitalize(
      faker.lorem.words(faker.datatype.number({min: 2, max: 4})),
    ),
    levels: schoolLevels,
  }
}

/**
 * Returns a random array of fake admin schools with lists to review
 */
export function adminListsFactory(collection: Item[]): SchoolLists[] {
  return [
    ...faker.helpers.uniqueArray(
      () => schoolListsFactory(collection),
      faker.datatype.number({min: 3, max: 5}),
    ),
  ]
}

export function schoolFactory(): Omit<School, 'hubspotId'> {
  return {
    id: faker.database.mongodbObjectId(),
    name: capitalize(
      faker.lorem.words(faker.datatype.number({min: 2, max: 4})),
    ),
    levels: [
      'Primario|Primer Grado',
      'Primario|Segundo Grado',
      'Primario|Tercer Grado',
      'Primario|Cuarto Grado',
      'Primario|Quinto Grado',
      'Primario|Sexto Grado',
      'Primario|Séptimo Grado',
      'Secundario|Primer Año',
      'Secundario|Segundo Año',
      'Secundario|Tercer Año',
      'Secundario|Cuarto Año',
      'Secundario|Quinto Año',
    ] as SearchLevel[],
    curriculum: [
      'Cambridge International Programme',
      'ELT (English Language Teaching)',
      'IB (International Baccalaureate)',
    ] as types.Curriculum[],
    province: 'Buenos Aires',
  }
}

/**
 * Returns a random array of fake items
 */
export function collectionFactory(): Item[] {
  return faker.helpers.uniqueArray(
    itemFactory,
    faker.datatype.number({min: 50, max: 100}),
  )
}

/**
 * Returns the locally-stored collection of fake items
 */
export function getLocalItems(): Item[] {
  return JSON.parse(window.localStorage.getItem('items') || '[]')
}

/**
 * Returns the locally-stored user lists
 */
export function getLocalLists(): List[] {
  return JSON.parse(window.localStorage.getItem('lists') || '[]')
}

/**
 * Returns the locally-stored admin lists
 */
export function getLocalAdminLists(): SchoolLists[] {
  return JSON.parse(window.localStorage.getItem('adminLists') || '[]')
}

function userFactory(type: User['type']): User {
  const firstName = faker.name.firstName()
  const lastName = faker.name.lastName()
  return {
    username: `${firstName} ${lastName}`,
    email: faker.internet.email(
      firstName.toLowerCase(),
      lastName.toLowerCase(),
    ),
    type,
  }
}

/**
 * Returns a fake admin user
 */
export function adminUserFactory() {
  return userFactory('teacherAdmin')
}

/**
 * Returns a fake teacher user
 */
export function teacherUserFactory() {
  return userFactory('teacher')
}

/**
 *  Returns the locally-stored school
 */
export function getSchool() {
  return JSON.parse(window.localStorage.getItem('school') || '{}')
}
