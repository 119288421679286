import React, {useEffect, useState} from 'react'
import {FieldValues, UseFormReturn} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import useSchool from '../hooks/useSchool'
import useUser from '../hooks/useUser'
import {SearchLevel} from '../types'
import useLevelMap from '../hooks/useLevelMap'

export type GradeSelectorFormData = {
  level?: SearchLevel
}

type Props<T extends FieldValues> = {
  screenLoading?: boolean
  form: UseFormReturn<T>
}

export default function LevelMapSelector<T extends FieldValues>({
  screenLoading = false,
  form: {
    formState: {errors},
    register,
    setValue,
    getValues,
  },
}: Props<T>) {
  const user = useUser()
  const schoolFromAPI = useSchool()
  const {data: levelMapFromApi = []} = useLevelMap()

  const loading = screenLoading || user.isLoading || schoolFromAPI.isLoading
  const [filteredLevels, setFilteredLevels] = useState(levelMapFromApi)
  const [inputValue, setInputValue] = useState('')
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()
    const filtered = levelMapFromApi.filter(level =>
      level.description.toLowerCase().includes(value),
    )
    setFilteredLevels(filtered)
    setInputValue(event.target.value)
    setValue('level' as any, '' as any)
    setIsDropdownVisible(true)
  }

  useEffect(() => {
    const selectedLevel = getValues('level' as any)
    if (selectedLevel) {
      const foundLevel = levelMapFromApi.find(
        level => level.code === selectedLevel,
      )
      if (foundLevel) {
        setInputValue(`${foundLevel.description}`)
      }
    }
  }, [levelMapFromApi, getValues])

  return (
    <div className="relative">
      <label className="text-sm text-slate-500" htmlFor="academicLevel">
        Academic Level
      </label>
      <input
        type="text"
        value={inputValue}
        onChange={event => {
          handleInputChange(event)
          register('level' as any, {required: 'Required'}).onChange(event)
        }}
        onFocus={() => setIsDropdownVisible(true)}
        onBlur={() => setTimeout(() => setIsDropdownVisible(false), 200)}
        disabled={loading}
        placeholder="Select level"
        className="block h-9 w-full border-b-2 border-slate-300 bg-transparent pl-0 text-base outline-none focus:border-blue-700"
      />
      {isDropdownVisible && filteredLevels.length > 0 && (
        <ul
          className="absolute z-10 mt-1 max-h-24 w-full overflow-y-auto rounded border border-slate-300 bg-white shadow-md"
          style={{maxHeight: '5rem'}}>
          {' '}
          {/* Limitar la altura del desplegable */}
          {filteredLevels.map(level => (
            <li
              key={level._id}
              onClick={() => {
                setValue('level' as any, level.code as any)
                setInputValue(level.description)
                setIsDropdownVisible(false)
              }}
              className="cursor-pointer p-2 hover:bg-blue-100">
              {level.description}
            </li>
          ))}
        </ul>
      )}
      <div className="mt-1 h-5">
        <ErrorMessage
          className="text-xs text-red-600"
          errors={errors}
          name={'level' as any}
          as="div"
        />
      </div>
    </div>
  )
}
